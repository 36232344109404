// All paths so we can easily search and reuse them
const PATHS = {
  metadata: {
    reports: '/get-report',
  },
};

export enum ReportSources {
  BR = 'business-review',
  BRDev = 'business-review-dev',
}

export default PATHS;
