import { Suspense } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from '@ergeon/core-components';

import authService from '../common/auth';

import Preloader from './Preloader';
import { ErrorBoundary } from './Error';
import LoggedContent from './LoggedContent';

import './App.scss';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Preloader />}>
          <Auth authService={authService}>
            {() => <LoggedContent />}
          </Auth>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
