import ErgAuthService from '@ergeon/erg-auth';

type OptionalSettings = {
  cookieName?: string;
}

const COOKIE_PARAM_NAME = 'cookieName';
const OPTIONAL_SETTINGS: OptionalSettings = {};
if (process.env.REACT_APP_AUTH_COOKIE_NAME) {
  OPTIONAL_SETTINGS[COOKIE_PARAM_NAME] = process.env.REACT_APP_AUTH_COOKIE_NAME;
}

export const authService = new ErgAuthService({
  ...OPTIONAL_SETTINGS,
  appId: 'ergeon',
  idService: process.env.REACT_APP_ID_SERVICE,
  userDetailsEndpoint: `${process.env.REACT_APP_API_HOST}/api/v1/authenticate/help/`,
  level: process.env.REACT_APP_HOST_ENV || 'development',
  mockJWT: process.env.REACT_APP_MOCK_JWT,
});

export default authService;
