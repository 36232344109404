import { createContext } from 'react';

import { TableState } from 'react-table';

export type TableStateContext = {
  tableState: TableState | null;
  setTableState: React.Dispatch<React.SetStateAction<TableState | null>>;
}

const defaultContext = {
  tableState: null,
  setTableState: () => undefined,
};

export const ReportContext = createContext<TableStateContext>(defaultContext);
