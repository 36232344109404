import { memo, useState } from 'react';

import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TableState } from 'react-table';

import { defaultMetricsQuery } from '../api/customHooks';

import Home from './Home/lazy';
import Report from './Report/lazy';
import ErrorPage from './Error';
import { ReportContext } from './Report/components/ReportContext/ReportContext';

const metaDataQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultMetricsQuery,
      refetchOnWindowFocus: false,
    },
  },
});

function LoggedContent() {
  const [tableState, setTableState] = useState<TableState | null>(null);
  return (
    <QueryClientProvider client={metaDataQueryClient}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/:source/:report">
          <ReportContext.Provider value={{ tableState, setTableState }}>
            <Report />
          </ReportContext.Provider>
        </Route>
        <Route path="*">
          <ErrorPage title="Page not Found!" />
        </Route>
      </Switch>
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </QueryClientProvider>
  );
}

export default memo(LoggedContent);
