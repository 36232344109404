import AWS from 'aws-sdk';
import { ErrorType } from 'aws-sdk/clients/es';
import axios from 'axios';
import { useQuery, QueryFunction, QueryKey } from 'react-query';

import config from './config';
import { Reports, Metrics } from './types';

export const defaultMetricsQuery: QueryFunction<string, QueryKey> = async({ queryKey: [url] }) => {
  if (typeof url === 'string') {
    const { data } = await axios.get(
      url, {
        headers: {
          'Authorization': `Token ${process.env.REACT_APP_LAMBDA_AUTH_TOKEN}`,
        },
      },
    );
    return data;
  }
  throw new Error('Invalid QueryKey');
};

export const defaultS3Query = async() => {
  try {
    const credentials = new AWS.Credentials({
      accessKeyId: config.awsAccess,
      secretAccessKey: config.awsSecret,
    });
    const s3Client = new AWS.S3({ credentials, region: config.awsRegion });
    const data = await s3Client.getObject({ Bucket: config.s3Bucket, Key: config.s3Key }).promise();
    const { Body: body } = data;
    return body && JSON.parse(body.toString('utf-8'));
  } catch (error) {
    throw new Error(error as ErrorType);
  }
};

export function useReportsMetaData(source: string) {
  return useQuery<Reports, Error>(`${config.lambda}/${source}`);
}

export function useMetricsData(isMetaDataLoaded: boolean) {
  return useQuery<Metrics, Error>([''], defaultS3Query, { useErrorBoundary: true, enabled: isMetaDataLoaded });
}
