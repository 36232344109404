import { Component, useEffect, ErrorInfo } from 'react';

import { QueryErrorResetBoundary } from 'react-query';
import * as Sentry from '@sentry/browser';
import { TopPanel, Button } from '@ergeon/core-components';

import lazyWhale from './assets/lazy-whale.png';

import './Error.scss';

type ErrorPageProps = {
  title: string,
  description?: string,
  error?: Error,
  reset?: () => void,
}

export default function ErrorPage(props: ErrorPageProps) {
  const {
    title,
    description,
    error,
    reset,
  } = props;

  useEffect(function logError() {
    if (error) {
      const err = error instanceof Error ? error : new Error(JSON.stringify(error)); // stringify for plain obj
      Sentry.captureException(err);
    }
  }, [error]);

  return (
    <section className="error-page">
      <TopPanel showNavigation={false} />
      <div className="error-page__img-container">
        <img src={lazyWhale} alt="Lazy whale" />
      </div>
      <h2 className="center spacing before__is-12">{title}</h2>
      <p className="center spacing before__is-12">{description}</p>
      {reset &&
        <div className="center spacing before__is-64">
          <Button flavor="regular" size="large" taste="solid" onClick={reset}>Retry</Button>
        </div>
      }
    </section>
  );
}

export class ErrorBoundary extends Component {
  state: { error?: Error } = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, errorInfo, error });
    const err = error instanceof Error ? error : new Error(JSON.stringify(error)); // stringify for plain obj
    Sentry.captureException(err);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorPage title="Oops! Something unexpected happened" error={error} reset={reset} />
          )}
        </QueryErrorResetBoundary>
      );
    }
    return children;
  }
}
