import PATHS from './paths';

export type ApiConfig = {
  base?: string,
  onError?(error: Error): void,
};

const config = {
  lambda: `${process.env.REACT_APP_METADATA_LAMBDA!}${PATHS.metadata.reports}`,
  s3Bucket: `${process.env.REACT_APP_METRICS_S3_BUCKET}`,
  s3Key: `${process.env.REACT_APP_METRICS_S3_KEY}`,
  awsAccess: `${process.env.REACT_APP_AWS_ACCESS}`,
  awsSecret: `${process.env.REACT_APP_AWS_SECRET}`,
  awsRegion: `${process.env.REACT_APP_AWS_REGION}`,
};

export default config;
