import { Spinner } from '@ergeon/core-components';

function Preloader({ loadingMsg = 'Loading...' }: { loadingMsg?: string }) {
  return (
    <section className="CenteredLayout">
      <Spinner active borderWidth={0.16} color="blue" size={64} />
      <p>{loadingMsg}</p>
    </section>
  );
}

export default Preloader;
