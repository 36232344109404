import React, { useCallback, useMemo } from 'react';

import { isUpcomingFeaturesEnabled } from '@ergeon/erg-utils-js';

import SocialFooter from './SocialFooter';

import { TypeOfFooter } from './constants';
import type { FooterInterface, MenuItemsList } from './types';
import { PHONE_NUMBER } from '../../../constants';
import { getPhoneFormatter } from '../../utils/usePhoneFormatter';

type useFooterProps = {
  typeOfFooter?: TypeOfFooter;
} & Pick<
  FooterInterface,
  | 'UTMquery'
  | 'ergeonUrl'
  | 'fencequotingUrl'
  | 'citiesUrl'
>;

const useFooter = ({
  UTMquery,
  ergeonUrl,
  fencequotingUrl,
  citiesUrl,
  typeOfFooter = TypeOfFooter.Default,
}: useFooterProps) => {
  const getErgeonURL = useCallback(
    (match: string = '') => {
      return `${ergeonUrl}${match}${UTMquery}`;
    },
    [ergeonUrl, UTMquery]
  );

  const menuItems: MenuItemsList[] = useMemo(() => {
    const companyItems = {
      column: 'Company',
      items: [
        {
          children: 'About',
          href: getErgeonURL('about-ergeon/'),
        },
        {
          children: 'Careers',
          href: getErgeonURL('careers/'),
        },
        {
          children: 'Pro',
          href: getErgeonURL('pro/'),
        },
      ],
    } as MenuItemsList;

    const productItems = {
      column: 'Products',
      items: [
        {
          children: 'Fence',
          href: getErgeonURL('fences/'),
        },
        {
          children: 'Artificial grass',
          href: getErgeonURL('artificial-grass/'),
        },
        ...(isUpcomingFeaturesEnabled('APPS-23') ? [
          {
            children: 'Decks',
            href: getErgeonURL('decks/'),
          },
        ] : []),
      ],
    } as MenuItemsList;

    const toolsItems = {
      column: 'Tools',
      items: [
        {
          children: 'Fence Calculator',
          href: `${fencequotingUrl}${UTMquery}`,
        },
        {
          children: 'Fence Gallery',
          href: getErgeonURL('gallery/'),
        },
        {
          children: 'Blog',
          href: getErgeonURL('blog/'),
        },
      ],
    } as MenuItemsList;

    const helpItems = {
      column: 'Help',
      items: [
        {
          children: 'Help Center',
          href: getErgeonURL('help/'),
        },
        {
          children: 'Contact Us',
          href: getErgeonURL('contacts/'),
        },
        {
          children: getPhoneFormatter(PHONE_NUMBER).display,
          className: 'phone',
          href: `tel:${PHONE_NUMBER}`,
        },
        {
          children: <SocialFooter hasMarginTop={false} />,
          isHTML: true,
        },
      ],
    } as MenuItemsList;

    switch (typeOfFooter) {
      case TypeOfFooter.Default:
        companyItems.items.push({
          children: 'Locations',
          href: `${citiesUrl}${UTMquery}`,
        });

        return [toolsItems, companyItems, helpItems];
      case TypeOfFooter.Simple:
        companyItems.items.push({
          children: 'Help Center',
          href: getErgeonURL('help/'),
        });

        return [toolsItems, companyItems, productItems];
      default:
        throw new Error('Selected menu item not recognized.');
    }
  }, [
    typeOfFooter,
    fencequotingUrl,
    UTMquery,
    citiesUrl,
  ]);

  return { getErgeonURL, menuItems };
};

export default useFooter;
